
const countries = [
	"", 	
	"South Africa", 	
	"Zambia 	",
	"DR Congo", 	
    "Eastern Africa",	
	"Tanzania",
    "Nigeria",
 	"Ethiopia",	
    "Egypt",
    "Kenya 	",
    "Uganda 	",
    "Algeria 	",
 	"Sudan 	",
 	"Morocco 	",
 	"Angola 	",
 	"Mozambique 	",
 	"Ghana 	",
 	"Madagascar 	",
 	"Cameroon ",
 	"Côte d'Ivoire 	",
 	"Niger 	",
 	"Burkina Faso ",
 	"Mali 	",
 	"Malawi ",
 	"Senegal 	",
 	"Chad 	",
 	"Somalia 	",
 	"Zimbabwe 	",
 	"Guinea 	",
 	"Rwanda 	",
 	"Benin 	",
 	"Burundi 	",
 	"Tunisia 	",
 	"South Sudan 	",
 	"Togo ",
 	"Sierra Leone ",
 	"Libya 	",
 	"Congo 	",
 	"Liberia 	",
 	"Central African Republic 	",
 	"Mauritania 	",
 	"Eritrea 	",
 	"Namibia 	",
 	"Gambia 	",
 	"Botswana ",
 	"Gabon 	",
 	"Lesotho 	",
 	"Guinea-Bissau 	",
 	"Equatorial Guinea 	",
 	"Mauritius 	",
 	"Eswatini 	",
 	"Djibouti ",
 	"Comoros 	",
 	"Cabo Verde 	",
 	"Sao Tome & Principe 	",
 	"Seychelles"
]

export function countrie_Arrays() {
    return countries;
}