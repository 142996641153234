import React, { useState, useEffect } from "react"
import PropTypes from 'prop-types'
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"
// Redux
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"

import { useAuth } from "../../../Contexts/AuthContext"
import { Db } from "../../../Database/init-firebase";
import { collection, getDocs} from "firebase/firestore"
import avatar2 from "../../../assets/images/users/avatar-smart-school.png";
import { MetaTags } from "react-meta-tags"

const ProfileMenu = props => {
  const [menu, setMenu] = useState(false)

  const { currentUser, logout } = useAuth()
  const { uid } = currentUser;
  const [profile, setProfile] = useState();

   useEffect(() =>{
    const fetchData = async() =>{
      let list = [];
      try {
        const querySnapshot = await getDocs(collection(Db, "TEACHERS"));
            querySnapshot.forEach((doc) => {
            list.push({id: doc.id, ...doc.data().allfield}); 
        })
        const result = list.find((stId) => stId.id === uid)
        setProfile(result);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, [uid])


  return (
    <React.Fragment>
       <MetaTags>
          <title>Smart School | user profile</title>
        </MetaTags>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item waves-effect"
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={profile?.img ? profile.img : avatar2}
            alt="user"
          />

          <span className="d-none d-xl-inline-block ms-1"> {profile?.email ? profile?.email : "Admin"} </span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">

          <DropdownItem tag="a" href="/#">
            <i className="bx bx-user font-size-16 align-middle me-1"></i>
            {props.t("View Profile")}
          </DropdownItem>
         
          <DropdownItem tag="a" href="#">
            <span className="badge bg-success float-end">11</span><i
              className="bx bx-wrench font-size-16 align-middle me-1"></i>
            {props.t("Settings")}
          </DropdownItem>
        
          <div className="dropdown-divider" />
          <Link 
           onClick={() => {
            logout()
            localStorage.removeItem("token")
          }}
           className="dropdown-item text-danger">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger"></i>
            <span>{props.t("Logout")}</span>
          </Link>

        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any
}

const mapStatetoProps = state => {
  const { error, success } = state.Profile
  return { error, success }
}

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
)