
const Grades = [
    0,1,2,4,5,6,7,8,9,10,11,12
]
const Religions = [
    "Atheism/Agnosticism",
    "Bahá’í",
    "Buddhism",
    "Christianity",
    "Confucianism",
    "Druze",
    "Gnosticism",
    "Hinduism",
    "Islam",
    "Jainism",
    "Judaism",
    "Rastafarianism",
    "Shinto",
    "Sikhism",
    "Zoroastrianism",
    "Traditional African Religions",
    "African Diaspora Religions",
    "Indigenous American Religions",

]



const teacherGrade = [
    { value: 'grade-1', label: 'grade-1' },
    { value: 'grade-2', label: 'grade-2' },
    { value: 'grade-3', label: 'grade-3' },
    { value: 'grade-4', label: 'grade-4' },
    { value: 'grade-5', label: 'grade-5' },
    { value: 'grade-6', label: 'grade-6' },
    { value: 'grade-7', label: 'grade-7' },
    { value: 'grade-8', label: 'grade-8' },
    { value: 'grade-9', label: 'grade-9' },
    { value: 'grade-10', label: 'grade-10' },
    { value: 'grade-11', label: 'grade-11' },
    { value: 'grade-12', label: 'grade-12' },
  ];

export function grade_Arrays() {
    return Grades;
}
export function religions_Arrays() {
    return Religions;
}
export function teacher_Grade_Arrays() {
    return teacherGrade;
}


